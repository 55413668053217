/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // Remove text nodes
        function clean(node)
        {
          for(var n = 0; n < node.childNodes.length; n ++)
          {
            var child = node.childNodes[n];
            if
            (
              child.nodeType === 8 || (child.nodeType === 3 && !/\S/.test(child.nodeValue))
            )
            {
              node.removeChild(child);
              n --;
            }
            else if(child.nodeType === 1)
            {
              clean(child);
            }
          }
        }
        clean(document);
        // Scroll to top of single blog page
        $('.to-top').click(function() {
          $("html, body").animate({ scrollTop: 0 }, 500);
          return false;
        });
        // Colorbox pop-up
        $('.gallery a').colorbox(
          {
            rel:'gal',
            maxWidth: '85%'
          }
        );
        // matchHeight on selected elements
        $(function() {
          $('.the-title').matchHeight({
            byRow: true
          });
        });

        // Scroll to top on click
        $('.to-top').click(function(){
          $('body, html').animate({ scrollTop: 0 }, 500);
        });

        // Toggle Mobile Menu
        $('.toggle-menu').click(function(){
          $('.mobile-nav').stop().slideToggle(500);
        });

        // stop dummy links
        $('a[href="#"]').click(function(e){
          e.preventDefault();
        });

        // Slick Carousel
        $(document).ready(function(){
          $('.carousel-content').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: '.carousel-years',
            appendArrows: '.slick-controls'
          });
          $('.carousel-years').slick({
            slidesToShow: 7,
            slidesToScroll: 1,
            asNavFor: '.carousel-content',
            dots: false,
            arrows: false,
            centerMode: true,
            focusOnSelect: true,
            responsive: [
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 5
                }
              },
              {
                breakpoint: 767,
                settings: {
                  centerPadding: '1px',
                  slidesToShow: 3
                }
              }
            ]
          }); 
          $('.gallery').slick({
            slidesToShow: 7,
            slidesToScroll: 1,
            arrows: true,
            appendArrows: '.gallery',
            centerMode: true,
            focusOnSelect: true,
            centerPadding: '10px',
            asNavFor: '.main-img'
          });
          $('.main-img').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.gallery'
          });
        });
        // matchHeight
        $(function() {
          $('.cpt-article').matchHeight();
          $('.the-sectors').matchHeight();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page  
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Blog page
    'blog': {
      init: function() {
        // JavaScript to be fired on the blog page
        $(function() {
          $('.featured-img, .content-holder').matchHeight();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the blog page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
